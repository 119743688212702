import {API_UPLOAD_PATH} from "../../_ae/helpers/UIHelper";

export function removeCSSClass(ele, cls) {
    const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
    ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = (pathname= "") => process.env.REACT_APP_PUBLIC_URL + pathname;
export const toAbsoluteApiUrl = (pathname= "") => process.env.REACT_APP_API_URL + pathname;
export const toAbsoluteUploadUrl = (pathname= "") => process.env.REACT_APP_API_URL + '/' + API_UPLOAD_PATH + pathname;
export const toEntityFileNameUrl = (entity, folder, fileNameProp = 'fileName' ) => toAbsoluteUploadUrl(`/${folder}/${entity[fileNameProp]}`);
