import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";
import {setEmyRole} from "../../../../_metronic/i18n";
import {toAbsoluteUrl} from "../../../../_metronic/_helpers";
import {ROLES} from "../../../../_ae/config";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  VerifyEmail: "[Verify Email] Action"
};

// console.log(roles)
const initialAuthState = {
  user: undefined,
  token: undefined
};

export const reducer = persistReducer(
  { storage, key: "v706-demo3-auth", whitelist: ["user", "token"/*,"role"*/] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token } = action.payload;
        return { token, user: undefined };
      }

      case actionTypes.Register: {
        const { token } = action.payload;
        return { token, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.VerifyEmail: {

        // const { role } = action.payload;
        // console.log('switch', role)
        return { ...state };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: token => ({ type: actionTypes.Login, payload: { token } }),
  emailVerified: () => ({ type: actionTypes.VerifyEmail }),
  register: token => ({  type: actionTypes.Register, payload: { token } }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } })
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });
  yield takeLatest(actionTypes.VerifyEmail, function* verifiedEmail() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
    setEmyRole(user.roles[0], false);
    window.location.replace(toAbsoluteUrl())
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data:{_data:user}  } = yield getUserByToken();
    // alert()
    // setEmyRole(user.roles[0], false);

    let role = ROLES.U;

    if(user.roles.includes(ROLES.SA)) role = ROLES.SA
    else role = user.roles[0]
    setEmyRole(role, false);

    yield put(actions.fulfillUser(user));
  });
}
