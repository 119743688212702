import React, {Suspense} from "react";
import ReactDOM from "react-dom";
import {getConfig} from "./_metronic/i18n";
import * as _redux from "./redux";
import axios from "axios";
import store from "./redux/store";
import {ROLES} from "./_ae/config";
import {SITE_NAME} from "./_ae/helpers/UIHelper";
// import App from "./app/App";


/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/* const mock = */
// projects.mockAxios(axios);

/**
 * Inject metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store);

document.title = SITE_NAME;

const RTL = React.lazy(() => import("./index-rtl"));
const RTL_SECONDARY = React.lazy(() => import("./index-rtl-secondary"));
const LTR = React.lazy(() => import("./index-ltr"));
const LTR_SECONDARY = React.lazy(() => import("./index-ltr-secondary"));

const config = getConfig();
// console.log(config)
let AppInstance = undefined;
if(config.rtl){
  if(config.role !== ROLES.A){
    AppInstance = RTL;
  }else{
    AppInstance = RTL_SECONDARY;
  }
}else {
  if(config.role !== ROLES.A){
    AppInstance = LTR;
  }else{
    AppInstance = LTR_SECONDARY;
  }
}

ReactDOM.render(
  <Suspense fallback={<div/>}>
    <AppInstance/>
  </Suspense>
  ,
  document.getElementById("root")
);