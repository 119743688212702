import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";

// import {slice as skillsSlice} from "../app/modules/Skills/_redux/slice";
// import {slice as locationsSlice} from "../app/modules/Locations/_redux/slice";
// import {slice as countriesSlice} from "../app/modules/Countries/_redux/slice";
// import {slice as categoriesSlice} from "../app/modules/_Categories/_redux/slice";
// import {slice as subcategoriesSlice} from "../app/modules/Subcategories/_redux/slice";
// import {slice as emyProjectsSlice} from "../app/modules/EmyProjects/_redux/slice";
// import {slice as projectTypesSlice} from "../app/modules/ProjectTypes/_redux/slice";
// import {slice as projectCommentsSlice} from "../app/modules/ProjectComments/_redux/slice";
// import {slice as projectProposalsSlice} from "../app/modules/ProjectProposals/_redux/slice";
// import {slice as legalFormsSlice} from "../app/modules/LegalForms/_redux/slice";
// import {slice as chatSlice} from "../app/modules/Teams/_redux/slice";
// import {slice as teamsSlice} from "../app/modules/Teams/_redux/slice";
// import {slice as calendarSlice} from "../app/modules/Calendar/_redux/slice";
// import {slice as contactsSlice} from "../app/modules/Contacts/_redux/slice";
// import {slice as functionsSlice} from "../app/modules/Functions/_redux/slice";
// import {slice as PaymentTermsSlice} from "../app/modules/PaymentTerms/_redux/slice";
//
// import {slice as EmyServicesSlice} from "../app/modules/EmyServices/_redux/slice";
// import {slice as EmyServiceReviewsSlice} from "../app/modules/EmyServices/_redux/Reviews/slice";
//
// import {slice as EmyProductsSlice} from "../app/modules/EmyProducts/_redux/slice";
// import {slice as EmyProductReviewsSlice} from "../app/modules/EmyProducts/_redux/Reviews/slice";

import {slice as usersSlice} from "../app/modules/Users/_redux/slice";
import {slice as menuCategoriesSlice} from "../app/modules/Categories/_redux/slice";
import {slice as menusSlice} from "../app/modules/Menus/_redux/slice";
import {slice as appVideosSlice} from "../app/modules/AppVideos/_redux/slice";
import {slice as userVideosSlice} from "../app/modules/UserVideos/_redux/slice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  // emy_projects: emyProjectsSlice.reducer,
  // locations: locationsSlice.reducer,
  // legal_forms: legalFormsSlice.reducer,
  // chat: chatSlice.reducer,
  // teams: teamsSlice.reducer,
  // contacts: contactsSlice.reducer,
  // calendar_events: calendarSlice.reducer,
  // functions: functionsSlice.reducer,
  // skills: skillsSlice.reducer,
  // countries: countriesSlice.reducer,
  // categories: categoriesSlice.reducer,
  // subcategories: subcategoriesSlice.reducer,
  // project_types: projectTypesSlice.reducer,
  // project_comments: projectCommentsSlice.reducer,
  // project_proposals: projectProposalsSlice.reducer,
  // payment_terms: PaymentTermsSlice.reducer,
  //
  // emy_services: EmyServicesSlice.reducer,
  // emy_service_reviews: EmyServiceReviewsSlice.reducer,
  //
  // emy_products: EmyProductsSlice.reducer,
  // emy_product_reviews: EmyProductReviewsSlice.reducer,

  users: usersSlice.reducer,
  menu_categories: menuCategoriesSlice.reducer,
  menus: menusSlice.reducer,
  app_videos: appVideosSlice.reducer,
  user_videos: userVideosSlice.reducer,
  // user_videos: userVideosCategoriesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
