import React from "react";
import PropTypes from 'prop-types';
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../_metronic/_helpers";


/* TODO
*   list all icons
*   icon classes
* */

export const SVG_ICON = {
  LIST:'/Layout/Layout-grid.svg',
  PLUS:'/Navigation/Plus.svg',
  EDIT:'/Design/Edit.svg',
  DELETE:'/Navigation/Close.svg',
  SAVE:'/Navigation/Check.svg',
  MAP_MARKER:'/Map/Marker1.svg',
  LOCATION:'/Map/Position.svg',
  EMAIL: '/Communication/Mail-at.svg',
  HEARTH: '/General/Heart.svg',

  ADD_USER: '/Communication/Add-user.svg',
  USERS:'/Communication/Group.svg',
  MENU_CATEGORIES:'/Layout/Layout-4-blocks.svg',
  MENUS:'/Text/Menu.svg',
  APP_VIDEOS:'/Media/Movie-Lane2.svg',
  USER_VIDEOS:'/Media/Movie-lane1.svg',
}


export const AESVG = ({ path, variant, size , className, prefix = '/icons', ...props}) =>{
  let classes = [className, "svg-icon", "menu-icon"];
  if(variant) classes.push(`svg-icon-${variant}`)
  if(size) classes.push(`svg-icon-${size}`)
  return (
    <span className={classes.join(' ')} {...props}>
      <SVG src={toAbsoluteUrl(`/media/svg/${prefix+path}`)} style={{pointerEvents: 'none' }}/>
    </span>
  )
}
AESVG.propTypes = {
  // icon: PropTypes.oneOf(["General/Search.svg"]),//todo
  path: PropTypes.string,
  variant: PropTypes.string
};