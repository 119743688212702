import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_ae/helpers/UIHelper";
import {SVG_ICON} from "../../../../_ae/components/svg";
// import {getRoutes} from "../../../Routes";

export const CONTEXT = "menu_categories";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "USER",
  plural: "CATEGORIES",
}

export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "title": 'aaa',
    "instruction": "Voluptate aut molestias earum consequuntur vel. Possimus in in odit maxime velit nisi. Molestiae mollitia velit minus repellendus. Sed unde occaecati dolore vel reiciendis iusto voluptates.",
    "ingredients": "Et aut eos dolor nobis ea rerum cumque. Culpa adipisci et et praesentium. Aperiam libero qui harum. A ut itaque non voluptatibus inventore.",
    "description": "Ducimus repudiandae tempore possimus et. Et pariatur fugit aspernatur consequatur beatae et vel. Facilis iusto molestias quis eveniet.",
    "iconFileName": undefined,
    "fileName": undefined
  } :
  {
    "title": '',
    "instruction": '',
    "ingredients": '',
    "description": '',
    "iconFileName": undefined,
    "fileName": undefined
  }