import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_ae/helpers/UIHelper";
import {SVG_ICON} from "../../../../_ae/components/svg";
// import {getRoutes} from "../../../Routes";

export const CONTEXT = "menus";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "USER",
  plural: "CATEGORIES",
}

export const defaultEntity = {
  "title": "",
  "description": "",
  "category": undefined,
  "fileName": undefined,
}