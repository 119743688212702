import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_ae/helpers/UIHelper";
import {SVG_ICON} from "../../../../_ae/components/svg";
// import {getRoutes} from "../../../Routes";

export const CONTEXT = "app_videos";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "USER",
  plural: "CATEGORIES",
}

export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "title": "Telephone Station Installer and Repairer",
    "menu": undefined,
    "fileName": undefined
  } :
  {
    "title": '',
    "menu": undefined,
    "fileName": undefined
  }