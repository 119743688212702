import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_ae/helpers/UIHelper";
import {SVG_ICON} from "../../../../_ae/components/svg";
// import {getRoutes} from "../../../Routes";

export const CONTEXT = "users";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "USER",
  plural: "USERS",
}

export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "firstName": "aaa",
    "lastName": "bbb",
    "username": "aelfannir",
    "email": "aelfannir@gmail.com",
    "phone": "+212 690922699",
    "roles": [
      "ROLE_USER"
    ],
    "password": undefined,
    "_confirm": undefined,
  } :
  {
    "firstName": "",
    "lastName": "",
    "username": "",
    "email": "",
    "phone": "",
    "roles": [
      "ROLE_USER"
    ],
    "password": undefined,
    "_confirm": undefined,
  }