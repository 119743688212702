export const CONFIG = {
  roles: {
    V: "ROLE_VISITOR",
    C: "ROLE_CONSUMER",
    U: "ROLE_USER",
    A: "ROLE_ADMIN",
    SA: "ROLE_SUPER_ADMIN"
  },
  auth: {
    login: {
      google: false
    },
    register: {
      enabled: false
    },
  }
}

//fixme: duplicated
export const ROLES = CONFIG.roles;


/* UI */
const ROLE_VARIANT = {
  [CONFIG.roles.SA] : 'dark',
  [CONFIG.roles.A] : 'primary',
  [CONFIG.roles.C] : 'info',
  [CONFIG.roles.U] : 'secondary',
}
export const getRoleVariant = role => ROLE_VARIANT[role]