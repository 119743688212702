import {createSlice} from "@reduxjs/toolkit";
import {AEMetadata} from "../../../../_ae/AEPagination";
import {getConfig} from "../../../../_metronic/i18n";
import {defaultEntity} from "../helpers/ModuleHelpers";

export const actionTypes = {
  List: "list",
  Delete: "deleteDialog",
  Edit: "editForm",
  Details: "details"
}

export const slice = createSlice({
  name: 'menu_categories',
  initialState: {
    [actionTypes.List]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {
          perPage : 12
        },
        { field: 'firstName'},
        {},
        {'*':[],"location":{"*":[], 'city':{'*':[]}}}
      ),
    },
    [actionTypes.Delete]:{
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },
    [actionTypes.Details]:{
      entity: defaultEntity,
      isLoading: false,
      error: null,
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;

      // console.log(state[type], state, type)
      state[type].error = null;
      state[type].isLoading = true;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if(!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }

      if([actionTypes.Details].includes(type)){
        const {id, is_saved} = entity;

        state[type].entity.is_saved = is_saved
      }

      if([actionTypes.List].includes(type)){
        const {id, is_saved} = entity;

        state[type].entitites = state[type].entities.map(e=>{
          if (e.id === id) {
            e.is_saved = is_saved
          }
          return e;
        });
      }
    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = data;
      }
    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;
      state[type].error = null;
      state[type].isLoading = false;


      if([actionTypes.Edit, actionTypes.Delete, actionTypes.Details].includes(type)){
        const { entity } = action.payload;
        console.log(type, entity)
        state[type].entity = entity;
        if([actionTypes.Edit].includes(type)){
          state[type].validationErrors = [];
        }
      }
      if([actionTypes.List].includes(type)){
        const { entities, meta } = action.payload;
        if(meta){
          const {pagination:{total,pages}} = meta;
          state[type].meta.pagination.total = total;
          state[type].meta.pagination.pages = pages;
        }
        state[type].entities = entities;
      }
    },
    //
    addComment:(state, action)=>{
      const { type, comment } = action.payload;
      if(!type in actionTypes) return;

      if([actionTypes.Details].includes(type)){
        state[type].entity.comments.push(comment)
      }
    }
  }
});